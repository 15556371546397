import React, { useEffect } from 'react'
import { PageProps } from 'gatsby'
import { Head, SmallPage, Footer } from '~/components'
import Banner from '~/assets/img/Hero-Sucesso.jpg'
import OutlinedText from '~/assets/svg/Sucesso.svg'
import OutlinedTextMobile from '~/assets/svg/Sucesso-mobile.svg'

const Success = ({ location }: PageProps) => {
  useEffect(() => {
    document.getElementById('react-modal-overlay')?.remove()
  }, [])

  return (
    <>
      <Head location={location} title="Mensagem enviada - Atendimento Alínea" />
      <SmallPage
        banner={Banner}
        title="Mensagem enviada"
        outlinedText={OutlinedText}
        outlinedTextMobile={OutlinedTextMobile}
        message={
          <>
            Sua mensagem foi enviada para nossa equipe de relacionamento. <br />
            Em breve entraremos em contato para melhor atende-lo.
          </>
        }
        buttonLabel="Voltar"
      />
      <Footer />
    </>
  )
}

export default Success
